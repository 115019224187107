import { useState, type ReactNode } from "react";
import { Eye, EyeOff } from "lucide-react";
import { useTranslation } from "react-i18next";

type VariantType = "default" | "light";

interface InputVariant {
  border: string;
  label: string;
  bg: string;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  mask?: string;
  maskType?: "phone" | "cpf" | "cnpj" | "date" | "cep" | "currency" | "custom";
  variant?: VariantType;
  icon?: ReactNode;
  onIconClick?: () => void;
  required?: boolean;
  readOnly?: boolean;
}

const variants: Record<VariantType, InputVariant> = {
  default: {
    border: "border-primary border-2",
    label: "text-primary",
    bg: "bg-white",
  },
  light: {
    border: "border-primary border",
    label: "text-text-secondary",
    bg: "bg-white",
  },
};

const Input = ({
  label,
  error,
  variant = "default",
  value,
  onChange,
  className = "",
  icon,
  onIconClick,
  type = "text",
  required,
  readOnly,
  ...props
}: InputProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const currentVariant = variants[variant];

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
    onIconClick?.();
  };

  const inputType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  const isControlled = value !== undefined;

  return (
    <div className="w-full">
      {label && (
        <label
          className={`mb-2 block text-sm font-medium ${currentVariant.label}`}
        >
          {label}
          {required && <span className="ml-1 text-primary">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          autoComplete="on"
          type={inputType}
          value={isControlled ? value : undefined}
          defaultValue={!isControlled ? props.defaultValue : undefined}
          onChange={isControlled && onChange ? onChange : undefined}
          className={`w-full rounded-lg px-4 py-3 ${currentVariant.border} ${currentVariant.bg} ${
            icon ? "pr-10" : ""
          } ${readOnly ? "cursor-default" : ""} ${className}`}
          required={required}
          readOnly={readOnly}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={handlePasswordToggle}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-text-secondary"
            aria-label={
              showPassword ? t("input.password.hide") : t("input.password.show")
            }
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5" />
            ) : (
              <Eye className="h-5 w-5" />
            )}
          </button>
        )}
        {icon && type !== "password" && (
          <button
            type="button"
            onClick={onIconClick}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-text-secondary"
            aria-label={t("input.aria.iconButton")}
          >
            {icon}
          </button>
        )}
      </div>
      {error && <span className="mt-1 text-xs text-error">{error}</span>}
    </div>
  );
};

export default Input;
