// src/router/routes/protected.routes.ts
import { lazy } from "react";
import { RouteType } from "router/types";

const protectedRoutes: Array<RouteType> = [
  {
    key: "convite",
    path: "/convite/aceitar/:uuid",
    component: lazy(() => import("features/invite/components/InvitePage")),
    authority: "public",
    meta: { layout: "blank", pageContainerType: "gutterless" },
  },
];

export default protectedRoutes;
