import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "features/auth/api";
import { companyApi } from "features/company/api";
import { profileSelectApi } from "features/profile/api";
import authReducer from "features/auth/slices/authSlice";
import selectedCompanyReducer from "features/user/slices/selectedCompanySlice";
import { crudApi } from "features/crud/api";
import { locationSelectApi } from "features/location/api";
import { serverTableApi } from "common/components/ServerTable/api";
import { userApi } from "features/user/api";
import { businessGroupApi } from "features/businessGroup/api";
import { inviteApi } from "features/invite/api";
import { balanceApi } from "features/initialBalance/api";
import { bondApi } from "features/bond/api";
import { accountsPayableApi } from "features/accountsPayable/api";
import { entriesApi } from "features/entry/api";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [profileSelectApi.reducerPath]: profileSelectApi.reducer,
    [crudApi.reducerPath]: crudApi.reducer,
    [locationSelectApi.reducerPath]: locationSelectApi.reducer,
    [serverTableApi.reducerPath]: serverTableApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [inviteApi.reducerPath]: inviteApi.reducer,
    auth: authReducer,
    selectedCompany: selectedCompanyReducer,
    [businessGroupApi.reducerPath]: businessGroupApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer,
    [bondApi.reducerPath]: bondApi.reducer,
    [accountsPayableApi.reducerPath]: accountsPayableApi.reducer,
    [entriesApi.reducerPath]: entriesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          "crudApi.queries",
          "locationSelectApi.queries",
          "companyApi.queries",
        ],
        ignoredActions: [
          "crudApi/executeQuery/fulfilled",
          "locationSelectApi/executeQuery/fulfilled",
          "companyApi/executeQuery/fulfilled",
        ],
      },
    }).concat(
      authApi.middleware,
      companyApi.middleware,
      profileSelectApi.middleware,
      locationSelectApi.middleware,
      crudApi.middleware,
      serverTableApi.middleware,
      userApi.middleware,
      businessGroupApi.middleware,
      inviteApi.middleware,
      balanceApi.middleware,
      bondApi.middleware,
      accountsPayableApi.middleware,
      entriesApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
