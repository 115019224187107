export const removeMask = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

export const formatCNPJ = (cnpj: string): string => {
  const cleaned = removeMask(cnpj);
  return cleaned.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5",
  );
};

export const formatPhone = (phone?: string): string => {
  if (!phone) return "";
  const numbers = removeMask(phone);
  return numbers
    .replace(/^(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{1})(\d{4})(\d)/, "$1 $2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
};
