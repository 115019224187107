// src/features/invite/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  InviteResponse,
  InviteQueryParams,
  CreateInviteRequest,
  InviteByUuidResponse,
} from "features/invite/types";

export const inviteApi = createApi({
  reducerPath: "inviteApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Invite"],
  endpoints: (builder) => ({
    getInvites: builder.query<InviteResponse, InviteQueryParams | void>({
      query: (params) => ({
        url: "convite",
        method: "GET",
        params: params
          ? {
              filtro_generico: params.filtro_generico,
              page: params.page,
              perPage: params.perPage,
              sortDirection: params.sortDirection,
              sortBy: params.sortBy,
            }
          : undefined,
      }),
      providesTags: ["Invite"],
      transformResponse: (response: InviteResponse) => {
        if (!response.metadata) {
          response.metadata = {
            totalRecords: response.metadata,
            totalPages: 1,
            currentPage: 1,
          };
        }
        return response;
      },
    }),
    getInvite: builder.query<InviteResponse, string>({
      query: (uuid) => ({
        url: `convite/${uuid}`,
        method: "GET",
      }),
    }),
    acceptInvite: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `convite/aceitar/${uuid}`,
        method: "POST",
      }),
    }),
    cancelInvite: builder.mutation<{ mensagem: string }, number>({
      query: (inviteId) => ({
        url: `convite/cancelar/${inviteId}`,
        method: "POST",
      }),
      invalidatesTags: ["Invite"],
    }),
    resendInvite: builder.mutation<{ mensagem: string }, number>({
      query: (inviteId) => ({
        url: `convite/enviarEmail/${inviteId}`,
        method: "POST",
      }),
      invalidatesTags: ["Invite"],
    }),
    createInvite: builder.mutation<void, CreateInviteRequest>({
      query: (body) => ({
        url: "convite",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invite"],
    }),
    getInviteByUuid: builder.query<InviteByUuidResponse, string>({
      query: (uuid) => ({
        url: `convite/${uuid}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInvitesQuery,
  useGetInviteQuery,
  useAcceptInviteMutation,
  useCancelInviteMutation,
  useResendInviteMutation,
  useCreateInviteMutation,
  useGetInviteByUuidQuery,
} = inviteApi;
