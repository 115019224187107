// src/common/components/Dashboard/components/Sidebar/components/UserCompany/index.tsx
import { RefreshCcw, Upload } from "lucide-react";
import Button from "common/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setIsCompanyModalOpen } from "features/user/slices/selectedCompanySlice";
import { useTranslation } from "react-i18next";
import { formatCNPJ } from "common/utils/formatters";
import { LoggedUserData } from "features/user/types";
import { useState, useEffect } from "react";

const UserCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<RootState, LoggedUserData | null>(
    (state) => state.auth?.user,
  );

  const [hasImageError, setHasImageError] = useState(false);

  const company = user?.empresa_logada;

  const handleLogoUpload = () => {
    // Implement logo upload logic here
    console.log("Upload logo clicked");
  };

  const handleImageError = () => {
    setHasImageError(true);
  };

  useEffect(() => {
    setHasImageError(false);
  }, [company?.logo_empresa]);

  return (
    <div className="px-4 py-2 flex flex-col gap-3 justify-center items-center">
      <div className="w-full flex items-center justify-center">
        {company?.logo_empresa && !hasImageError ? (
          <img
            src={company.logo_empresa}
            alt={
              company.nome_fantasia || t("dashboard.userCompany.companyLogo")
            }
            className="h-full w-auto object-contain"
            onError={handleImageError}
          />
        ) : (
          <button
            onClick={handleLogoUpload}
            className="w-32 h-32 rounded-full border-2 border-dashed border-gray-300 flex flex-col items-center justify-center gap-2 hover:border-primary transition-colors cursor-pointer"
          >
            <Upload className="w-6 h-6 text-gray-400" />
            <span className="text-sm text-gray-500">
              {hasImageError
                ? t("dashboard.userCompany.uploadLogo")
                : t("dashboard.userCompany.uploadLogo")}
            </span>
          </button>
        )}
      </div>

      <div className="space-y-0.5">
        <p className="break-words uppercase font-medium text-base">
          {company?.razao_social}
          {company?.cnpj && (
            <>
              {" - "}
              <span>{formatCNPJ(company.cnpj)}</span>
            </>
          )}
        </p>
        <p className="text-sm">
          {company?.endereco}, nº {company?.numero}, Bairro {company?.bairro}
        </p>
        <p className="text-sm">
          {company?.municipio_ibge?.nome_municipio}
          {company?.municipio_ibge?.nome_municipio && (
            <>
              {" - "}
              {company?.municipio_ibge?.uf_ibge?.sigla_uf}
            </>
          )}
        </p>
      </div>

      <Button
        variant="primary"
        onClick={() => dispatch(setIsCompanyModalOpen(true))}
        className="text-sm flex items-center justify-center gap-2 w-2/3"
      >
        <RefreshCcw className="w-4 h-4" />
        <span>{t("dashboard.userCompany.changeCompany")}</span>
      </Button>
    </div>
  );
};

export default UserCompany;
