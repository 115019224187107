// src/features/businessGroup/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  BusinessGroupRequest,
  CompanyGroupResponse,
} from "features/businessGroup/types";

export const businessGroupApi = createApi({
  reducerPath: "businessGroupApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["BusinessGroup"],
  endpoints: (builder) => ({
    getCompanyGroup: builder.query<CompanyGroupResponse, void>({
      query: (empresa) => ({
        url: "grupoempresarial",
        params: { nome_grupo_empresarial: `F=${empresa}` },
        method: "GET",
      }),
      providesTags: ["BusinessGroup"],
    }),
    createBusinessCompany: builder.mutation<
      CompanyGroupResponse,
      BusinessGroupRequest
    >({
      query: (credentials) => ({
        url: "grupoempresarial",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newGroup } = await queryFulfilled;
          dispatch(
            businessGroupApi.util.updateQueryData(
              "getCompanyGroup",
              undefined,
              (draft) => {
                if (draft.data && newGroup.data) {
                  draft.data.push(...newGroup.data);
                }
              },
            ),
          );
        } catch {
          // If the mutation fails, we don't need to do anything
        }
      },
      invalidatesTags: ["BusinessGroup"],
    }),
  }),
});

export const { useGetCompanyGroupQuery, useCreateBusinessCompanyMutation } =
  businessGroupApi;
