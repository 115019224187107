// src/features/auth/components/Login/components/UserCompanyAccessModal/index.tsx
import { Search } from "lucide-react";
import { useCompanySelection } from "./hooks/useCompanySelection";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import { useTranslation } from "react-i18next";
import { setSelectedCompanyStorage } from "./utils/userCompanyAccessUtil";
import { CompanySelectionItem } from "./types";
import { ApiErrorResponse } from "features/auth/types";
import { formatCNPJ } from "common/utils/formatters";
import Spinner from "common/components/Spinner";
import { useEffect } from "react";

interface UserCompanyAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCompanySelect?: () => void;
  isLoadingUser?: boolean;
}

const UserCompanyAccessModal = ({
  isOpen,
  onClose,
  onCompanySelect,
  isLoadingUser,
}: UserCompanyAccessModalProps) => {
  const { t } = useTranslation();
  const {
    companies,
    handleCompanySelect,
    isLoading,
    isError,
    error,
    searchTerm,
    isFetching,
    handleSearchChange,
    refetchCompanies,
  } = useCompanySelection({ isOpen, onClose });

  useEffect(() => {
    if (isOpen) {
      refetchCompanies();
    }
  }, [isOpen, refetchCompanies]);

  const handleSelectCompany = async (company: CompanySelectionItem) => {
    await handleCompanySelect(company);
    setSelectedCompanyStorage(company);
    if (onCompanySelect) {
      onCompanySelect();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("auth.login.companySelection.title")}
      size="xl"
      isLoading={isLoading || isFetching || isLoadingUser}
      loadingMessage={
        <div className="flex items-center justify-center">
          <div className="text-center">
            <Spinner size="xl" className="mx-auto mb-4" />
            <span className="text-text-secondary">Carregando...</span>
          </div>
        </div>
      }
      showCloseButton={true}
      closeOnBackdropClick={false}
    >
      <div className="space-y-6">
        {isError && error && (
          <div className="rounded-lg bg-error-bg p-4 text-error mb-4">
            <div className="text-center">
              {t(`modal.errors.${(error as ApiErrorResponse).statusCode}`) ||
                t("modal.errors.default")}
            </div>
          </div>
        )}

        <Input
          placeholder={t("auth.login.companySelection.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => handleSearchChange(e.target.value)}
          icon={<Search className="h-5 w-5" />}
          autoFocus
        />

        <div className="overflow-auto max-h-96">
          <table className="w-full">
            <thead className="bg-table-header-bg sticky top-0">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-text">
                  {t("auth.login.companySelection.table.company")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-text-secondary">
              {companies.map((company) => (
                <tr
                  key={company.empresa_id}
                  onClick={() =>
                    handleSelectCompany(company as CompanySelectionItem)
                  }
                  className="cursor-pointer hover:bg-table-hover-bg transition-colors"
                >
                  <td className="px-6 py-4">
                    <div>
                      <div className="text-sm font-medium text-text">
                        {company.nome_fantasia || company.razao_social}
                      </div>
                      <div className="text-sm text-text-secondary">
                        {t("auth.login.companySelection.table.cnpj")}{" "}
                        {formatCNPJ(company.cnpj)}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {!isLoading && companies.length === 0 && (
                <tr>
                  <td className="px-6 py-4 text-center text-text-secondary">
                    {t("auth.login.companySelection.noResults")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default UserCompanyAccessModal;
