// src/features/profile/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { PermissionResponse, ProfileResponse } from "features/profile/types";
import { baseQueryWithAuth } from "config/baseQuery";
import { ProfileQueryParams } from "features/profile/types";
import { MenuItem } from "features/user/types";

interface MenuResponse {
  mensagem: string;
  data: MenuItem[];
}

interface Permission {
  permissao_id: number;
  nome_permissao: string;
}

interface CreateProfileRequest {
  nome_perfil: string;
  perfil_ativo: boolean;
  perfil_menu: Array<{ menu_id: number }>;
  perfil_permissoes: Array<{ permissao_id: number }>;
}

interface CreateProfileResponse {
  mensagem: string;
  data: {
    perfil_id: number;
    nome_perfil: string;
  };
}

interface ProfileDetailResponse {
  mensagem: string;
  data: {
    perfil_id: number;
    nome_perfil: string;
    perfil_ativo: boolean;
    perfil_menu: Array<{
      menu_id: number;
      menu_superior_id: number | null;
      nome_menu: string;
      caminho_menu: string | null;
    }>;
    perfil_permissoes: Array<{
      permissao_id: number;
      nome_permissao: string;
    }>;
  };
}

export const profileSelectApi = createApi({
  reducerPath: "profileSelectApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Profile", "Menu"],
  endpoints: (builder) => ({
    getProfileList: builder.query<ProfileResponse, ProfileQueryParams>({
      query: (params) => ({
        url: "perfil/getListaPerfil",
        method: "GET",
        params: params
          ? {
              filtro_generico: params.filtro_generico,
              page: params.page,
              perPage: params.perPage,
              sortDirection: params.sortDirection,
              sortBy: params.sortBy,
              perfil_ativo: params.perfil_ativo,
            }
          : undefined,
      }),
      providesTags: ["Profile"],
    }),
    getMenus: builder.query<MenuItem[], void>({
      query: () => ({
        url: "perfil/menu",
        method: "GET",
      }),
      transformResponse: (response: MenuResponse) => response.data,
      providesTags: ["Menu"],
    }),
    getPermissions: builder.query<Permission[], void>({
      query: () => ({
        url: "/perfil/permissao",
        method: "GET",
      }),
      transformResponse: (response: PermissionResponse) => response.data,
    }),
    createProfile: builder.mutation<
      CreateProfileResponse,
      CreateProfileRequest
    >({
      query: (data) => ({
        url: "/perfil",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        "Profile",
        { type: "Profile", id: result?.data.perfil_id },
        "Menu",
      ],
    }),
    getProfileById: builder.query<ProfileDetailResponse, number>({
      query: (id) => ({
        url: `/perfil/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Profile", id }, "Menu"],
    }),
  }),
});

export const {
  useGetProfileListQuery,
  useGetMenusQuery,
  useGetPermissionsQuery,
  useCreateProfileMutation,
  useGetProfileByIdQuery,
} = profileSelectApi;
