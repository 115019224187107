import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "features/user/types";

interface LocationConfig {
  mainPath: string;
  label: string;
  displayName?: string;
  subpaths?: Record<string, string>;
}

interface BreadcrumbProps {
  className?: string;
}

interface BreadcrumbItem {
  label: string;
  path: string;
}

const Breadcrumb = ({ className = "" }: BreadcrumbProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );

  const staticLocationConfigs: Record<string, LocationConfig> = {
    login: {
      mainPath: "/login",
      label: "Login",
    },
  };

  const dynamicLocationConfigs = useMemo(() => {
    const configs: Record<string, LocationConfig> = {};

    if (!menuProfile) return configs;

    const menuMap = menuProfile.reduce(
      (acc, item) => {
        acc[item.menu_id] = item;
        return acc;
      },
      {} as Record<number, MenuItem>,
    );

    menuProfile.forEach((item) => {
      if (item.caminho_menu) {
        const pathParts = item.caminho_menu.split("/").filter(Boolean);
        const pathSegment = pathParts[0];

        if (!pathSegment) return;

        let currentItem = item;
        while (currentItem.menu_superior_id) {
          const superiorItem = menuMap[currentItem.menu_superior_id];
          if (!superiorItem) break;
          currentItem = superiorItem;
        }

        configs[pathSegment] = {
          mainPath: `/${pathSegment}`,
          label: currentItem.nome_menu,
          subpaths: {
            ...(configs[pathSegment]?.subpaths || {}),
            [item.caminho_menu]: item.nome_menu,
          },
        };
      }
    });

    return configs;
  }, [menuProfile]);

  const allLocationConfigs = useMemo(
    () => ({
      ...staticLocationConfigs,
      ...dynamicLocationConfigs,
    }),
    [dynamicLocationConfigs],
  );

  const getBreadcrumbItems = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const pathSegment = pathParts[0] || "";
    const config = allLocationConfigs[pathSegment];

    if (!config || !menuProfile) {
      return [
        {
          label: t("Breadcrumb.overview"),
          path: "/",
        },
      ];
    }

    const breadcrumbs: BreadcrumbItem[] = [];

    const currentMenuItem = menuProfile.find((item) => {
      const fullPath = `/${pathParts.join("/")}`;
      return item.caminho_menu === fullPath;
    });

    if (currentMenuItem) {
      let item = currentMenuItem;
      const chain = [item];

      while (item.menu_superior_id) {
        const parentItem = menuProfile.find(
          (m) => m.menu_id === item.menu_superior_id,
        );
        if (parentItem) {
          chain.unshift(parentItem);
          item = parentItem;
        } else {
          break;
        }
      }

      breadcrumbs.push(
        ...chain.map((menuItem) => ({
          label: menuItem.nome_menu,
          path: menuItem.caminho_menu || "",
        })),
      );
    }

    return breadcrumbs.length > 0
      ? breadcrumbs
      : [
          {
            label: config.label,
            path: `/${pathSegment}`,
          },
        ];
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const breadcrumbItems = getBreadcrumbItems();

  return (
    <div
      className={`flex items-center gap-2 text-sm text-gray-500 ${className}`}
    >
      {breadcrumbItems.map((item, index) => (
        <div key={item.label + index} className="flex items-center">
          {index > 0 && <span className="mx-2">/</span>}
          {item.path ? (
            <button
              onClick={() => handleNavigate(item.path)}
              className="hover:text-primary cursor-pointer transition-colors duration-200"
            >
              {item.label}
            </button>
          ) : (
            <span className="text-gray-500">{item.label}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
