// src/common/components/Modal/index.tsx
import { ApiErrorResponse } from "features/auth/types";
import { useTranslation } from "react-i18next";
import { X } from "lucide-react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  size?:
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "auto"
    | "fit";
  className?: string;
  isLoading?: boolean;
  error?: ApiErrorResponse | null;
  loadingMessage?: string | React.ReactNode;
  closeOnBackdropClick?: boolean;
  showCloseButton?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  size = "md",
  className = "",
  isLoading = false,
  error = null,
  loadingMessage,
  closeOnBackdropClick = true,
  showCloseButton = false,
}: ModalProps) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const getErrorMessage = (status: number) => {
    return t(`modal.errors.${status}`) || t("modal.errors.default");
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && closeOnBackdropClick) {
      onClose();
    }
  };

  const sizeClasses = {
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl",
    "5xl": "max-w-5xl",
    auto: "max-w-full",
    fit: "max-w-fit",
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
      onClick={handleBackdropClick}
    >
      <div
        className={`relative flex flex-col w-full ${sizeClasses[size]} rounded-lg bg-white shadow-lg ${className} p-4`}
        role="dialog"
        aria-modal="true"
      >
        {/* Header */}
        <div className="flex-none p-4">
          <div className="flex items-center justify-center">
            {title && (
              <h2 className="text-2xl font-bold text-primary">{title}</h2>
            )}
            {showCloseButton && (
              <button
                onClick={onClose}
                className="absolute right-4 top-4 text-text-secondary hover:text-text"
                aria-label="Close modal"
              >
                <X className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>

        {/* Content with state handling */}
        <div className="flex-1 min-h-0 overflow-y-auto">
          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <span className="text-text-secondary">
                {loadingMessage || t("modal.loading")}
              </span>
            </div>
          ) : error ? (
            <div className="rounded-lg bg-error-bg p-4 text-error">
              <span className="text-center">
                {getErrorMessage(error.statusCode)}
              </span>
            </div>
          ) : (
            children
          )}
        </div>

        {/* Footer */}
        {!isLoading && !error && footer && (
          <div className="flex-none p-4">{footer}</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
