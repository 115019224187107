// src/features/entry/api/index.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetEntriesResponse,
  CreateManualEntryRequest,
  CreateManualEntryResponse,
} from "features/entry/types";

export const entriesApi = createApi({
  reducerPath: "entriesApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getAdjustmentOperations: builder.query<GetEntriesResponse, void>({
      query: () => ({
        url: "lancamento/operacoes",
        method: "GET",
        params: {
          apenas_ajuste: true,
        },
      }),
    }),

    createManualEntry: builder.mutation<
      CreateManualEntryResponse,
      CreateManualEntryRequest
    >({
      query: (data) => ({
        url: "lancamento/manual",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetAdjustmentOperationsQuery, useCreateManualEntryMutation } =
  entriesApi;
