import { Menu, User } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { setIsLogoutModalOpen } from "features/auth/slices/authSlice";
import Dropdown, { DropdownOption } from "common/components/Dropdown";
import LocationIndicator from "common/components/Breadcrumb";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  className?: string;
  onMenuClick: () => void;
}

const Header = ({ className, onMenuClick }: HeaderProps) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const handleUserMenuChange = (value: string) => {
    switch (value) {
      case "logout":
        dispatch(setIsLogoutModalOpen(true));
        break;
    }
  };

  const userMenuOptions: DropdownOption[] = [
    {
      value: "logout",
      label: t("dashboard.header.userMenu.logout"),
    },
  ];

  const UserButton = () => (
    <div className="flex items-center gap-2 py-1">
      <div className="w-8 h-8 bg-light-bg rounded-full flex items-center justify-center flex-shrink-0">
        <User className="w-5 h-5 text-text" />
      </div>
      <span className="text-sm text-text truncate whitespace-nowrap max-w-[100px] md:max-w-[150px]">
        {user?.nome_usuario}
      </span>
    </div>
  );

  return (
    <header
      className={`bg-light-bg border-b-2 border-b-table-header-bg px-4 flex flex-col sm:flex-row sm:h-16 fixed top-0 z-10 w-full ${className} lg:w-[calc(100%-280px)]`}
    >
      {/* Top row with menu and user dropdown on mobile and tablet */}
      <div className="h-16 flex items-center justify-between lg:hidden">
        <button
          onClick={onMenuClick}
          className="p-2 rounded-lg hover:bg-light-bg"
        >
          <Menu className="w-6 h-6 text-text" />
        </button>

        <div className="flex-shrink-0 w-auto flex items-center sm:hidden">
          <Dropdown
            options={userMenuOptions}
            onChange={handleUserMenuChange}
            renderOption={(option) => (
              <span className="text-sm">{option.label}</span>
            )}
            className="bg-primary-light rounded-lg"
            variant="light"
            placeholder={<UserButton />}
          />
        </div>
      </div>

      {/* Bottom row with breadcrumb */}
      <div className="h-12 sm:h-auto flex items-center sm:flex-1 min-w-0">
        <LocationIndicator className="min-w-0 flex-1" />
      </div>

      {/* Desktop user dropdown */}
      <div className="hidden sm:flex items-center flex-shrink-0 w-52 justify-end">
        <Dropdown
          options={userMenuOptions}
          onChange={handleUserMenuChange}
          renderOption={(option) => (
            <span className="text-sm">{option.label}</span>
          )}
          className="bg-primary-light rounded-lg"
          variant="light"
          placeholder={<UserButton />}
        />
      </div>
    </header>
  );
};

export default Header;
