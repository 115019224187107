// src/router/utils/routeUtils.ts
import { lazy } from "react";
import { RouteType } from "router/types";
import { MenuItem } from "features/user/types";

const getComponentPath = (menuPath: string): string => {
  const cleanPath = menuPath.replace(/^\//, "");

  const pathMap: Record<string, string> = {
    "cadastros/empresas/manutencao": "company/components/CompanyMaintenance",

    "cadastros/usuarios/manutencao": "user/components/UserMaintenance",

    "cadastros/usuarios/convites": "invite/components/UserInvite",

    "cadastros/usuarios/perfis": "profile/components/UserProfile",

    "gestao-obrigacoes/rol/manutencao": "bond/components/NORMaintenance",

    "gestao-obrigacoes/contas-pagar/manutencao":
      "accountsPayable/components/AccountsPayableMaintenance",

    "gestao-obrigacoes/conta/lancamentos-manuais":
      "account/components/ManualEntries",
  };

  return pathMap[cleanPath] || cleanPath;
};

export const mapMenuToRoutes = (menuItems: MenuItem[] = []): RouteType[] => {
  return menuItems
    .filter((item): item is MenuItem & { caminho_menu: string } =>
      Boolean(item.caminho_menu),
    )
    .map((item) => {
      const componentPath = getComponentPath(item.caminho_menu);

      return {
        key: `menu.${item.menu_id}`,
        path: item.caminho_menu,
        component: lazy(() =>
          import(`features/${componentPath}`).catch(
            () => import("router/components/NotFound"),
          ),
        ),
        authority: "authenticated",
        meta: {
          layout: "default",
        },
      };
    });
};
