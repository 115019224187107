// src/common/components/LoadingScreen/index.tsx
import { ReactNode } from "react";
import Spinner from "common/components/Spinner";

interface LoadingScreenProps {
  message?: string;
  className?: string;
  spinnerSize?: "sm" | "base" | "lg" | "xl" | "2xl";
  children?: ReactNode;
  modal?: boolean;
}

const LoadingScreen = ({
  message = "Carregando...",
  className = "",
  spinnerSize = "xl",
  children,
  modal = false,
}: LoadingScreenProps) => {
  const baseClasses = "flex flex-col items-center justify-center text-center";
  const modalClasses = "fixed inset-0 z-[9999] bg-black/50";
  const contentClasses = "p-6 bg-white rounded-lg shadow-lg";

  if (modal) {
    return (
      <div className={`${baseClasses} ${modalClasses}`}>
        <div className={contentClasses}>
          <Spinner size={spinnerSize} className="mx-auto mb-4" />
          <span className="text-text-secondary block">{message}</span>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={`${baseClasses} h-full bg-light-bg ${className}`}>
      <Spinner size={spinnerSize} className="mx-auto mb-4" />
      <span className="text-text-secondary">{message}</span>
      {children}
    </div>
  );
};

export default LoadingScreen;
