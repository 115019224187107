import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo, Suspense } from "react";
import { RootState } from "../store";
import { staticRoutes } from "./routes";
import { mapMenuToRoutes } from "./utils/routeUtils";
import Dashboard from "common/components/Dashboard";
import NotFound from "router/components/NotFound";
import LoadingScreen from "common/components/LoadingScreen";
import { useLoginMutation } from "features/auth/api";

const Router = () => {
  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading: isLoadingLogin }] = useLoginMutation();

  const dynamicRoutes = useMemo(
    () => mapMenuToRoutes(menuProfile || []),
    [menuProfile],
  );

  const allRoutes = [...staticRoutes, ...dynamicRoutes];

  if (isLoadingLogin && window.location.pathname !== "/login") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-light-bg">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/home" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      {/* Public Routes */}
      {allRoutes
        .filter((route) => route.authority === "public")
        .map(({ key, path, component: Component }) => (
          <Route
            key={key}
            path={path}
            element={
              <Suspense
                fallback={
                  <div className="flex items-center justify-center min-h-screen bg-light-bg">
                    <LoadingScreen />
                  </div>
                }
              >
                <Component />
              </Suspense>
            }
          />
        ))}

      {/* Protected Routes with Dashboard Layout */}
      <Route
        element={
          isAuthenticated ? (
            <Dashboard>
              <Outlet />
            </Dashboard>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      >
        {allRoutes
          .filter((route) => route.authority === "authenticated")
          .map(({ key, path, component: Component }) => (
            <Route
              key={key}
              path={path}
              element={
                <Suspense
                  fallback={
                    <div className="flex items-center justify-center h-full bg-light-bg">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <Component />
                </Suspense>
              }
            />
          ))}

        {/* Catch all route for authenticated area */}
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Global catch all route */}
      <Route
        path="*"
        element={
          isAuthenticated ? (
            <Navigate to="/home" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
  );
};

export default Router;
