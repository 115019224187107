// src/features/bond/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  NORDraftCalculationResponse,
  GetAccountBalanceResponse,
  SelicRate,
  saveNORDraftCalculationResponse,
  saveNORDraftCalculationRequest,
  BalanceteImportResponse,
  NORListResponse,
} from "features/bond/types";
import { TableQueryParams } from "common/components/ServerTable/types";

export const bondApi = createApi({
  reducerPath: "bondApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["NORDraft", "AccountBalances"],
  endpoints: (builder) => ({
    // getApuracaoMensalEmRascunho
    getNORDraftCalculation: builder.query<
      | NORDraftCalculationResponse
      | {
          mensagem: string;
          data: null;
        },
      void
    >({
      query: () => ({
        url: "apuracao/rascunho",
        method: "GET",
      }),
    }),

    // getTaxaSelicMesReferencia
    getTaxaSelicMesReferencia: builder.query<
      SelicRate,
      { mes: string; ano: string }
    >({
      query: (arg) => ({
        url: `selic?mes=${arg.mes}&ano=${arg.ano}`,
        method: "GET",
      }),
    }),

    // getSaldoConta
    getAccountBalance: builder.query<
      GetAccountBalanceResponse,
      {
        conta_id: number;
        mes?: string;
        ano?: string;
      }
    >({
      query: (arg) => ({
        url: `apuracao/saldoconta`,
        method: "GET",
        params: {
          conta_id: arg.conta_id,
          mes: arg.mes,
          ano: arg.ano,
        },
      }),
      providesTags: ["AccountBalances"],
    }),

    // salvarEditarApuracao
    saveNORCalculation: builder.mutation<
      saveNORDraftCalculationResponse,
      saveNORDraftCalculationRequest
    >({
      query: (data) => ({
        url: "/apuracao",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // limparApuracao
    clearNORCalculation: builder.mutation<
      { mensagem: string; data: boolean },
      number
    >({
      query: (id) => ({
        url: `/apuracao/${id}/limpar`,
        method: "POST",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            bondApi.util.updateQueryData(
              "getNORDraftCalculation",
              undefined,
              () => ({
                mensagem: "",
                data: null,
              }),
            ),
          );
        } catch {
          // Handle error if needed
        }
      },
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    //uploadbalancete
    uploadBalancete: builder.mutation<
      BalanceteImportResponse,
      { competencia: string; balanceteBase64: string }
    >({
      query: (data) => ({
        url: "/apuracao/uploadbalancete",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // efetivarApuracao
    consolidateNORCalculation: builder.mutation<
      saveNORDraftCalculationResponse,
      saveNORDraftCalculationRequest
    >({
      query: (data) => ({
        url: "/apuracao/efetivar",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // listarNORCalculations
    getNORList: builder.query<NORListResponse, TableQueryParams>({
      query: (params) => ({
        url: "/apuracao",
        method: "GET",
        params: {
          filtro_generico: params.filtro_generico,
          page: params.page,
          perPage: params.perPage,
          sortDirection: params.sortDirection,
          sortBy: params.sortBy,
        },
        keepUnusedDataFor: 0,
      }),
    }),
  }),
});

export const {
  useGetNORDraftCalculationQuery,
  useGetTaxaSelicMesReferenciaQuery,
  useGetAccountBalanceQuery,
  useSaveNORCalculationMutation,
  useLazyGetAccountBalanceQuery,
  useClearNORCalculationMutation,
  useUploadBalanceteMutation,
  useConsolidateNORCalculationMutation,
  useGetNORListQuery,
} = bondApi;
