// src/features/company/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  CompanySegmentResponse,
  CompanyTypeResponse,
  GetCompanyByIdResponse,
  CompanyParameterResponse,
  CompanyUserResponse,
  CompanyUserQueryParams,
  CompanyAccountsResponse,
} from "features/company/types";
import { CompanyParameterResponse as CompanyParameterResponseBond } from "features/bond/types";
import {
  RolModelResponse,
  RolModelAccount,
} from "features/company/types/rolModel";
import { Middleware } from "@reduxjs/toolkit";
import { GovernmentCompaniesResponse } from "features/company/types";

interface CrudUpdateAction {
  type: string;
  meta: {
    arg: {
      endpointName: string;
      originalArgs: {
        id: number;
      };
    };
  };
}

function isCrudUpdateAction(action: unknown): action is CrudUpdateAction {
  return (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    "meta" in action &&
    action.type === "crudApi/executeMutation/fulfilled" &&
    typeof action.meta === "object" &&
    action.meta !== null &&
    "arg" in action.meta &&
    typeof action.meta.arg === "object" &&
    action.meta.arg !== null &&
    "endpointName" in action.meta.arg &&
    action.meta.arg.endpointName === "update"
  );
}

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Company", "CompanyParameters", "CompanyUsers", "CompanyRolModel"],
  endpoints: (builder) => ({
    getCompanyType: builder.query<CompanyTypeResponse, void>({
      query: () => ({
        url: "empresa/getTipoEmpresa",
        method: "GET",
      }),
    }),

    getCompanySegment: builder.query<CompanySegmentResponse, void>({
      query: () => ({
        url: "empresa/getSegmentoEmpresa",
        method: "GET",
      }),
    }),

    getCompanyById: builder.query<GetCompanyByIdResponse, number>({
      query: (id) => ({
        url: `empresa/${id}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "Company", id }],
    }),

    getCompanyParameters: builder.query<CompanyParameterResponse, number>({
      query: (companyId) => ({
        url: `empresa/${companyId}/parametro`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [
        { type: "CompanyParameters", id },
      ],
    }),

    getCompanyUsers: builder.query<
      CompanyUserResponse,
      { id: number; params?: CompanyUserQueryParams }
    >({
      query: ({ id, params }) => ({
        url: `empresa/${id}/usuarios`,
        method: "GET",
        params: params
          ? {
              filtro_generico: params.filtro_generico,
              page: params.page,
              perPage: params.perPage,
              sortDirection: params.sortDirection,
              sortBy: params.sortBy,
            }
          : undefined,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "CompanyUsers", id }],
      transformResponse: (response: CompanyUserResponse) => {
        // Ensure we have metadata even if it's null from the API
        if (!response.data.metadata) {
          response.data.metadata = {
            totalRecords: response.data.usuarios.length,
            totalPages: 1,
            currentPage: 1,
          };
        }
        return response;
      },
    }),
    // getPlanoContaPadrao
    getCompanyNORModel: builder.query<
      RolModelResponse,
      { companyId: number; somente_ativo?: boolean }
    >({
      query: ({ companyId, somente_ativo }) => ({
        url: `empresa/${companyId}/planoconta`,
        params: {
          somente_ativo,
        },
      }),
    }),
    // Inserir Atualizar Modelo Plano de contas
    updateCompanyRolModel: builder.mutation<
      { mensagem: string; data: RolModelAccount[] },
      { companyId: number; accounts: Partial<RolModelAccount>[] }
    >({
      query: ({ companyId, accounts }) => ({
        url: `empresa/${companyId}/planoconta`,
        method: "POST",
        body: accounts,
      }),
    }),

    updateCompanyParameters: builder.mutation<
      void,
      {
        companyId: number;
        parameters: Array<{
          data_inicio_vigencia: string;
          data_fim_vigencia: string;
          perc_ped_aneel: number;
          perc_ped_cde: number;
          perc_ped_fndct: number;
          perc_ped_mme: number;
          perc_ee_aneel?: number;
          perc_ee_cde?: number;
          perc_ee_procel?: number;
        }>;
      }
    >({
      query: ({ companyId, parameters }) => ({
        url: `empresa/${companyId}/parametro`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: "CompanyParameters", id: companyId },
      ],
    }),

    getParametroEmpresaVigente: builder.query<
      CompanyParameterResponseBond,
      { companyId: number; month?: number; year?: number }
    >({
      query: ({ companyId, month, year }) => ({
        url: `empresa/${companyId}/parametro/vigente`,
        method: "GET",
        params: {
          mes: month,
          ano: year,
        },
      }),
    }),

    getCompanyAccounts: builder.query<
      CompanyAccountsResponse,
      { companyId: number; somente_ativo?: boolean; tipos?: number[] }
    >({
      query: ({ companyId, somente_ativo, tipos }) => ({
        url: `empresa/${companyId}/conta`,
        params: {
          somente_ativo,
          tipos: tipos?.join(","),
        },
      }),
    }),

    getGovernmentCompanies: builder.query<GovernmentCompaniesResponse, void>({
      query: () => ({
        url: "empresa/governo",
        method: "GET",
      }),
    }),
  }),
});

// Add this middleware to listen for CRUD API updates
export const invalidateCompanyOnCrudUpdate: Middleware =
  (api) => (next) => (action) => {
    const result = next(action);

    if (isCrudUpdateAction(action)) {
      api.dispatch(
        companyApi.util.invalidateTags([
          { type: "Company", id: action.meta.arg.originalArgs.id },
        ]),
      );
    }

    return result;
  };

// Export hooks for usage in components
export const {
  useGetCompanyTypeQuery,
  useGetCompanySegmentQuery,
  useGetCompanyByIdQuery,
  useGetCompanyParametersQuery,
  useGetCompanyUsersQuery,
  useGetCompanyNORModelQuery,
  useUpdateCompanyRolModelMutation,
  useUpdateCompanyParametersMutation,
  useGetParametroEmpresaVigenteQuery,
  useGetCompanyAccountsQuery,
  useGetGovernmentCompaniesQuery,
} = companyApi;
