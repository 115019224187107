import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import UserCompany from "common/components/Dashboard/components/Sidebar/components/UserCompany";
import { useSidebarNavigation, NavItem } from "./hooks/useSidebarNavigation";

interface SidebarContentProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose }: SidebarContentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navItems, toggleDropdown, isDropdownOpen } = useSidebarNavigation();

  useEffect(() => {
    onClose();
  }, [location.pathname, onClose]);

  const getItemClasses = (isActive: boolean, depth: number): string => {
    const baseClasses =
      "group relative flex items-center w-full transition-colors duration-200";
    const activeClasses = isActive
      ? "text-primary font-medium"
      : "text-text hover:text-primary";
    const depthClasses = depth > 0 ? "text-sm" : "text-base";
    return `${baseClasses} ${activeClasses} ${depthClasses}`;
  };

  const getIndentation = (depth: number): number => {
    const baseIndent = 16;
    const indentIncrement = 12;
    return baseIndent + depth * indentIncrement;
  };

  const renderNavItem = (item: NavItem, depth = 0) => {
    const hasSubItems =
      Array.isArray(item.subItems) && item.subItems.length > 0;
    const isActive = location.pathname.startsWith(item.path);
    const isOpen = isDropdownOpen(item.key);
    const paddingLeft = getIndentation(depth);
    const itemClasses = getItemClasses(isActive, depth);

    const renderVerticalLine = depth > 0 && (
      <div
        className="absolute w-px bg-light-border/30"
        style={{
          left: `${paddingLeft - 8}px`,
          top: 0,
          bottom: hasSubItems && isOpen ? "0" : "100%",
        }}
      />
    );

    const renderDotIndicator = depth > 0 && (
      <div className="w-1.5 h-1.5 rounded-full bg-current opacity-40" />
    );

    if (hasSubItems) {
      return (
        <div key={item.key} className="relative">
          {renderVerticalLine}
          <div className="flex w-full">
            {item.isNavigable ? (
              <button
                type="button"
                className={`${itemClasses} py-2 pr-2 flex-1`}
                style={{ paddingLeft: `${paddingLeft}px` }}
                onClick={() => {
                  toggleDropdown(item);
                  navigate(item.path);
                }}
              >
                <div className="flex items-center gap-2 min-w-0">
                  {renderDotIndicator}
                  <span className="truncate flex-1">{item.name}</span>
                </div>
              </button>
            ) : (
              <button
                type="button"
                className={`${itemClasses} py-2 pr-2 flex-1`}
                style={{ paddingLeft: `${paddingLeft}px` }}
                onClick={() => toggleDropdown(item)}
              >
                <div className="flex items-center gap-2 min-w-0">
                  {renderDotIndicator}
                  <span className="truncate flex-1">{item.name}</span>
                </div>
              </button>
            )}
            <button
              type="button"
              className="py-2 px-2 text-text hover:text-primary transition-colors duration-200"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown(item);
              }}
              aria-expanded={isOpen}
            >
              <ChevronDown
                className={`w-4 h-4 flex-shrink-0 transition-transform duration-200 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-200 ease-in-out ${
              isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            {item.subItems?.map((subItem) => renderNavItem(subItem, depth + 1))}
          </div>
        </div>
      );
    }

    if (!item.isNavigable) {
      return null;
    }

    return (
      <div key={item.key} className="relative">
        {renderVerticalLine}
        <NavLink
          to={item.path}
          className={({ isActive: linkActive }) => {
            return `${getItemClasses(linkActive, depth)} py-2 pr-4`;
          }}
          style={{ paddingLeft: `${paddingLeft}px` }}
          end
        >
          <div className="flex items-center gap-2 min-w-0">
            {renderDotIndicator}
            <span className="truncate">{item.name}</span>
          </div>
        </NavLink>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col">
      <UserCompany />
      <nav
        className="flex-1 overflow-y-auto py-4 space-y-1"
        role="navigation"
        aria-label="Main navigation"
      >
        {navItems.map((item) => renderNavItem(item))}
      </nav>
    </div>
  );
};

export default SidebarContent;
