// src/features/accountsPayable/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetAccountsPayableResponse,
  GetAccountsPayableStatusResponse,
  GetAccountsPayableParams,
  GetAccountPayableByIdResponse,
  UpdateAccountPayableResponse,
  UpdateAccountPayableRequest,
  CancelAccountPayableResponse,
  CancelAccountPayableRequest,
  CancelPaymentAccountPayableResponse,
  CreateAccountPayableResponse,
  CreateAccountPayableRequest,
} from "features/accountsPayable/types";

export const accountsPayableApi = createApi({
  reducerPath: "accountsPayableApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["AccountsPayable", "AccountsPayableStatus"],
  endpoints: (builder) => ({
    getAccountsPayableStatus: builder.query<
      GetAccountsPayableStatusResponse,
      void
    >({
      query: () => ({
        url: "contapagar/status",
        method: "GET",
      }),
      providesTags: ["AccountsPayableStatus"],
    }),

    getAccountsPayable: builder.query<
      GetAccountsPayableResponse,
      GetAccountsPayableParams
    >({
      query: (params) => ({
        url: "contapagar",
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.contas_pagar.map(({ contas_pagar_id }) => ({
                type: "AccountsPayable" as const,
                id: contas_pagar_id,
              })),
              { type: "AccountsPayable", id: "LIST" },
            ]
          : [{ type: "AccountsPayable", id: "LIST" }],
    }),

    getAccountPayableById: builder.query<GetAccountPayableByIdResponse, number>(
      {
        query: (id) => ({
          url: `contapagar/${id}`,
          method: "GET",
        }),
      },
    ),

    // edit
    updateAccountPayable: builder.mutation<
      UpdateAccountPayableResponse,
      UpdateAccountPayableRequest
    >({
      query: ({ contas_pagar_id, ...body }) => ({
        url: `contapagar/${contas_pagar_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { contas_pagar_id }) => [
        { type: "AccountsPayable", id: contas_pagar_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // pay
    payAccountPayable: builder.mutation<UpdateAccountPayableResponse, number>({
      query: (contas_pagar_id) => ({
        url: `contapagar/${contas_pagar_id}/baixar`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, contas_pagar_id) => [
        { type: "AccountsPayable", id: contas_pagar_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // cancel bill
    cancelAccountPayable: builder.mutation<
      CancelAccountPayableResponse,
      CancelAccountPayableRequest
    >({
      query: ({ contas_pagar_id, ...body }) => ({
        url: `contapagar/${contas_pagar_id}/cancelar`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { contas_pagar_id }) => [
        { type: "AccountsPayable", id: contas_pagar_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // cancel payment
    cancelPaymentAccountPayable: builder.mutation<
      CancelPaymentAccountPayableResponse,
      number
    >({
      query: (contas_pagar_id) => ({
        url: `contapagar/${contas_pagar_id}/cancelarbaixa`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, contas_pagar_id) => [
        { type: "AccountsPayable", id: contas_pagar_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // create payable
    createAccountPayable: builder.mutation<
      CreateAccountPayableResponse,
      CreateAccountPayableRequest
    >({
      query: (body) => ({
        url: `contapagar`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { conta_id }) => [
        { type: "AccountsPayable", id: conta_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAccountsPayableStatusQuery,
  useGetAccountsPayableQuery,
  useGetAccountPayableByIdQuery,
  useUpdateAccountPayableMutation,
  usePayAccountPayableMutation,
  useCancelAccountPayableMutation,
  useCancelPaymentAccountPayableMutation,
  useCreateAccountPayableMutation,
} = accountsPayableApi;
