import { createApi } from "@reduxjs/toolkit/query/react";
import { InitialBalanceRequest, InitialBalanceResponse } from "../types";
import { baseQueryWithAuth } from "config/baseQuery";

export const balanceApi = createApi({
  reducerPath: "balanceApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    createInitialBalance: builder.mutation<
      InitialBalanceResponse,
      { empresaId: number; data: InitialBalanceRequest }
    >({
      query: ({ empresaId, data }) => ({
        url: `empresa/${empresaId}/saldoinicial`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useCreateInitialBalanceMutation } = balanceApi;
