// src/features/crud/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import { ApiResponse, BaseEntity, CrudConfig } from "features/crud/types";
import { entityTransformers } from "features/crud/utils/transformer";

export const crudApi = createApi({
  reducerPath: "crudApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["CrudItem"],
  endpoints: (builder) => ({
    getList: builder.query<
      ApiResponse<BaseEntity[]>,
      {
        config: CrudConfig<BaseEntity>;
        entityType: string;
        params?: Record<string, string>;
      }
    >({
      query: ({ config, params }) => ({
        url: config.endpoints.list,
        params: params,
      }),
      providesTags: ["CrudItem"],
      transformResponse: (
        baseQueryReturnValue: unknown,
        _meta,
        arg,
      ): ApiResponse<BaseEntity[]> => {
        const response = baseQueryReturnValue as ApiResponse<
          Record<string, unknown>[]
        >;
        return {
          mensagem: response.mensagem,
          data: response.data.map((item) => ({
            id: item.id as string | number,
            ...entityTransformers[arg.entityType]?.response(item),
          })),
        };
      },
    }),

    getById: builder.query<
      ApiResponse<BaseEntity>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        entityType: string;
      }
    >({
      query: ({ config, id }) => ({
        url: `${config.endpoints.getById}/${id}`,
      }),
      providesTags: ["CrudItem"],
      transformResponse: (
        baseQueryReturnValue: unknown,
        _meta,
        arg,
      ): ApiResponse<BaseEntity> => {
        const response = baseQueryReturnValue as ApiResponse<
          Record<string, unknown>
        >;
        return {
          mensagem: response.mensagem,
          data: {
            id: response.data.id as string | number,
            ...entityTransformers[arg.entityType]?.response(response.data),
          },
        };
      },
    }),

    create: builder.mutation<
      ApiResponse<BaseEntity>,
      { config: CrudConfig<BaseEntity>; data: any; entityType: string }
    >({
      query: ({ config, data, entityType }) => {
        console.log("data", data);
        const transformedData =
          entityTransformers[entityType]?.request(data) ?? data;
        console.log("transformedData", transformedData);

        return {
          url: config.endpoints.create,
          method: "POST",
          body: transformedData,
        };
      },
      invalidatesTags: ["CrudItem"],
      transformResponse: (
        baseQueryReturnValue: unknown,
        _meta,
        arg,
      ): ApiResponse<BaseEntity> => {
        const response = baseQueryReturnValue as ApiResponse<
          Record<string, unknown>
        >;
        const transformedData = {
          id: response.data.id as string | number,
          ...entityTransformers[arg.entityType]?.response(response.data),
        };

        return {
          mensagem: response.mensagem,
          data: transformedData,
        };
      },
    }),

    update: builder.mutation<
      ApiResponse<BaseEntity>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        data: Partial<BaseEntity>;
        entityType: string;
      }
    >({
      query: ({ config, id, data, entityType }) => {
        const transformedData =
          entityTransformers[entityType]?.request(data) ?? data;

        return {
          url: `${config.endpoints.update}/${id}`,
          method: "PUT",
          body: transformedData,
        };
      },
      invalidatesTags: ["CrudItem"],
      transformResponse: (
        baseQueryReturnValue: unknown,
        _meta,
        arg,
      ): ApiResponse<BaseEntity> => {
        const response = baseQueryReturnValue as ApiResponse<
          Record<string, unknown>
        >;
        return {
          mensagem: response.mensagem,
          data: {
            id: response.data.id as string | number,
            ...entityTransformers[arg.entityType]?.response(response.data),
          },
        };
      },
    }),

    delete: builder.mutation<
      ApiResponse<null>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        entityType: string;
      }
    >({
      query: ({ config, id }) => ({
        url: `${config.endpoints.delete}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CrudItem"],
      transformResponse: (baseQueryReturnValue: unknown): ApiResponse<null> => {
        const response = baseQueryReturnValue as ApiResponse<null>;
        return response;
      },
    }),
  }),
});

export const {
  useGetListQuery,
  useGetByIdQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = crudApi;
